<template>
	<div class="page-schedule">
		<div class="filter">
			<div class="wrapper">
				<div class="types">
					<router-link to="/schedules/1" :class="{ active: query.type == 1 }">全部</router-link>
					<router-link to="/schedules/2" :class="{ active: query.type == 2 }">足球</router-link>
					<router-link to="/schedules/3" :class="{ active: query.type == 3 }">篮球</router-link>
					<router-link to="/schedules/99" :class="{ active: query.type == 99 }">其他</router-link>
					<router-link to="/schedules/10" :class="{ active: query.type == 10 }">完赛</router-link>
					<!-- <span class="active" style="left: 0"></span> -->
				</div>
				<span style="flex: 1"></span>
				<div class="dates">
					<a href="javascript:;" v-for="(item, index) in dates" :key="index" :class="[currentIndex === index ? 'active' : '']" @click="dateChange(item, index)">
						{{ item.weekday }}
						<br />
						{{ item.date }}
					</a>
				</div>
			</div>
		</div>
		<div class="wrapper">
			<div class="schedule" v-for="item in dateMatches" :key="item.date" ref="schedules">
				<div class="schedule-header">{{ item.date }}</div>
				<div class="schedule-body">
					<div class="empty" v-if="item.lists.length == 0">暂无直播</div>
					<ws-schedule-table v-else :lists="item.lists" @appointment="onAppointment" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { debounce, copy } from '@/utils';
import wsScheduleTable from '@/components/wsScheduleTable';

let timer = null;
export default {
	name: 'Schedule',
	components: {
		wsScheduleTable
	},
	data() {
		return {
			query: {
				type: 99,
				date: ''
			},
			dates: [],
			matches: {},

			scrollTop: 0,
			positions: []
		};
	},
	computed: {
		...mapState(['token']),
		appointmentIds() {
			const { appointments } = this.$store.state;
			var ids = [];
			Object.keys(appointments).forEach(date => {
				ids = ids.concat((appointments[date] || []).map(v => v.tournament_id));
			});
			return ids;
		},
		dateMatches() {
			const matches = [];
			this.dates.forEach(item => {
				let lists = this.matches[item.date];
				if (lists && Array.isArray(lists)) {
					matches.push({
						date: item.date,
						lists: lists.map(v => {
							v.appointment = false;
							return v;
						})
					});
				}
			});
			return matches;
		},
		currentIndex() {
			const idx = copy(this.positions)
				.reverse()
				.findIndex(v => v <= this.scrollTop);
			return idx == -1 ? 0 : this.positions.length - 1 - idx; // idx > 0 ? idx - 1 : 0;
		}
	},
	created() {
		// console.info('created')
		this.$loading(30000);
		window.addEventListener('scroll', e => {
			this.scrollTop = document.documentElement.scrollTop;
		});
		this.$bus.$on('ws.message', res => {
			if (res.event == 'pushMatch') {
				const state = res.data.findIndex(v => v.state == -1);
				if (state === -1) {
					res.data.forEach(info => {
						let { match_id, match_type, ...oinfo } = info;
						Object.keys(this.matches).forEach(date => {
							const match = (this.matches[date] || []).find(v => v.tournament_id == +match_id && v.tournament_type == +match_type);
							if (match) {
								Object.keys(oinfo).forEach(k => this.$set(match, k, oinfo[k]));
							}
						});
					});
				} else {
					this.getSchedules();
				}
				console.info('match', res.data);
			}
		});
		Object.assign(this.query, this.$route.params);
		this.getSchedules();
	},
	beforeRouteUpdate(to, from, next) {
		// console.info('update')
		this.$loading(30000);
		Object.assign(this.query, to.params);
		this.getSchedules();
		next();
	},
	beforeRouteLeave(to, from, next) {
		timer && clearTimeout(timer);
		next();
	},
	methods: {
		async getSchedules() {
			timer && clearTimeout(timer);
			const response = await this.$api.get(`/web/match_lists/${this.query.type}`);
			if (response) {
				const { date_list, match_list } = response.data;
				this.dates = Object.keys(date_list).map(date => {
					this.query.date = this.query.date || date;
					return {
						date,
						weekday: date_list[date]
					};
				});
				this.matches = match_list || {};
				timer = setTimeout(this.getSchedules, 600 * 1000);
				this.$nextTick(() => {
					this.positions = this.$refs.schedules.map(el => el.offsetTop - 170);
					this.$loadend();
				});
			}
		},
		dateChange(item, index) {
			const top = this.positions.length > index ? this.positions[index] : 0;
			window.scrollTo({
				top,
				behavior: 'smooth'
			});
		},
		async onAppointment(match) {
			if (!this.token) {
				return this.$bus.$emit('login');
			}
			const response = await this.$api.post(`/web/appointment`, {
				opt_type: match.is_reservation == 1 ? 0 : 1,
				tournament_id: match.tournament_id,
				tournament_type: match.tournament_type
			});
			if (response && response.code == 200) {
				this.$message.success(match.is_reservation == 1 ? '操作成功' : '预约成功');
				this.getSchedules();
			}
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/schedule.less');
</style>
